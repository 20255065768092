import { Router } from '@angular/router';

import { Auth, AuthProvider, GoogleAuthProvider, IdTokenResult, OAuthProvider, ParsedToken, authState, linkWithCredential } from '@angular/fire/auth';
import { Firestore, doc, setDoc } from '@angular/fire/firestore';
import { User, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from '@angular/fire/auth';
import { Injectable, signal } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Observable, from, map, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    public afs: Firestore, // Inject Firestore service
    private afAuth: Auth, // Inject Firebase auth service
    public router: Router,
    private functions: Functions
  ) {

    /* Saving user data in localstorage when T
    logged in and setting up null when logged out */
    authState(this.afAuth).subscribe((user) => {
      if (user) {
        this._loggedInUser.set(user);
      }
    });
  }

  private _loggedInUser = signal<User | null>(null);
  loggedInUser = this._loggedInUser.asReadonly();


  authState(): Observable<User | null> {
    return authState(this.afAuth);
  }

  get isAuthenticated(): Observable<boolean> {
    return authState(this.afAuth)
      .pipe(map(u => {
        return u != null;
      }));
  };

  get identityUser(): Observable<IdTokenResult | null> {

    return authState(this.afAuth).pipe(
      mergeMap(user => {

        if (user != null) {
          return from(user.getIdTokenResult())
        }
        else {
          return new Observable<null>;
        }
      }),
      map(idToken => {
        return idToken;
      }));
  };

  get userClaims(): Observable<ParsedToken | null> {

    return authState(this.afAuth).pipe(
      mergeMap(user => {

        if (user != null) {
          return from(user.getIdTokenResult())
        }
        else {
          return new Observable<null>;
        }
      }),
      map(idToken => {
        return idToken != null ? idToken['claims'] : null;
      }));
  };

  CurrentUser(): Observable<any> {
    return authState(this.afAuth);
  }

  // Sign in with email/password
  async SignIn(email: string, password: string, success: Function | null = null) {
    signInWithEmailAndPassword(this.afAuth, email, password)
      .then(async (result) => {
        this.SetUserData(result.user);
        authState(this.afAuth).subscribe(async (user) => {
          if (user) {
            const setSystemClaimsCallable = httpsCallable(this.functions, "setPortalSystemClaims");
            await setSystemClaimsCallable({ email: user.email });
            if (success) {
              success();
            }
            else {
              this.router.navigate(['select-organisation']);
            }
          }
        });
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  async GoogleAuth(success: Function | null = null) {
    var provider = new GoogleAuthProvider();
    provider.setDefaultLanguage('en');
    return await this.SocialSignIn(provider, success);
  }

  async MicrosoftAuth(success: Function | null = null) {
    var provider = new OAuthProvider('microsoft.com');
    provider.setDefaultLanguage('en');

    return await this.SocialSignIn(provider, success);
  }

  // Sign in with social provider
  private async SocialSignIn(provider: AuthProvider, success: Function | null) {

    signInWithPopup(this.afAuth, provider)
      .then(async (result) => {
        this.SetUserData(result.user);
        this.afAuth.onAuthStateChanged(async (user) => {
          if (user) {
            const setSystemClaimsCallable = httpsCallable(this.functions, "setPortalSystemClaims");
            await setSystemClaimsCallable({ email: user.email });
            
            if (success) {
              success();
            }
            else {
              this.router.navigate(['select-organisation']);
            }
          }
        });
      })
      .catch((error) => {
        console.error(error);

        if (error.code === 'auth/account-exists-with-different-credential') {

          //At this point, if the user is not logged in then we need to prompt them to log in using an existing 
          //authentication provider. If we can get a list on valid ones then it would be helpful to redirect to
          //next screen. From there we can get them to log in and then link

          const credential = OAuthProvider.credentialFromError(error);

          console.log(credential);

          if (this.afAuth.currentUser != null && credential != null) {
            linkWithCredential(this.afAuth.currentUser, credential);
          }
        }

      });
  }

  // UnlinkAccount(providerId: string) {
  //   unlink(this.userData, providerId);
  // }


  // Reset Forgot password
  ForgotPassword(passwordResetEmail: string) {
    sendPasswordResetEmail(this.afAuth, passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in and email is verified
  // get isLoggedIn(): boolean {
  //   const user = JSON.parse(localStorage.getItem('user')!);
  //   return user !== null && user.emailVerified !== false ? true : false;
  // }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    const userRef = doc(this.afs, `users/${user.uid}`)

    const userData: any = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return setDoc(userRef, userData, {
      merge: true,
    });
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    });
  }
}